/* our custom styles */

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* PNUM Theme */

@media (min-width: 1200px) {
    .container {width: 1000px;}
}

#portal-header #portal-languageselector {
    float: right;
    display: inline-block;
    margin-right: 29px;
    padding-top: 5px;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #a7daf2;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #4d4d4d;
    background-color: #fafafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: justify;
    line-height: 1.8em;
}

a {
    color: #282c2d;
    text-decoration: none;
    background: transparent;
}

h1.documentFirstHeading {
    padding-bottom: 9px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid rgb(204, 204, 204);
    font-size: 26px;
}

div#parent-fieldname-text > h3 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
    text-decoration: underline #cccccc;
    margin-bottom: 0.5em;
}

div#parent-fieldname-text > h4 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
text-decoration: underline #cccccc;
    margin: 20px 0 0.5em 0;
    text-align: left;
}

h5 {
    padding: 20px 0 5px;
    text-decoration: underline #cccccc;
}

div.documentDescription.description {
    font-size: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 10px;
    font-style: italic;
}

.invisible-grid {
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    background: transparent;
}

table.journals {
    width: 100%;
    max-width: 40%;
    border: 0px solid #fafafa;
    background-color: #fafafa;
}

table.journals td {
    padding-left: 5px;
}

table.scientific-council {
    max-width: 100%;
    border: 0px solid #fafafa;
    background-color: #fafafa;
    border-radius: 4px;
    font-weight: 300;
    color: #696969;
}

table.scientific-council td {
    padding-left: 10px;
}

h4 > sub {
    bottom: .1em;
}
.responsive-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

aside#portal-footer-signature.portlet.portletClassic {
    display: none;
}

#portal-footer-wrapper p {
    border-bottom: none;
    padding-bottom: 0;
}

#portal-footer-wrapper {
    padding: 20px 15px;
    background-color: #2e3133;
    color: #cccccc;
    text-align: center;
    clear: both;
}

img.logo {
    width: 1070px;
    float: left;
}

span.abstract {
    display: none;

}

p.abstract:hover {
    cursor: zoom-in;
}

p.abstract:hover span.abstract {
    display: block;
    padding: 5px 10px;
    cursor: zoom-in;
    background-color: #F2F2F2;
}

div.pfg-form.formid-join {
    width: 80%;
}

div.pfg-form.formid-aderir {
    width: 80%;
}

.portlet .portletHeader {
    background: #f2f1f1;
    font-weight: 400;
    font-size: 16px;
    padding: 5px 15px;
}

.portlet .portletContent {
    font-size: 10px;
    border-top: 1px solid #e5e5e5;
    background: #fff;
    font-weight: 400;
}

.portlet .portletFooter {
    border-top: 1px solid #e5e5e5;
    background: #fcfcfd;
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 300;
    box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}

/* Plone navbar */

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #ffffff;
    border-radius: 4px;
}

.plone-navbar-toggle:hover {
    background-color: #a5b5c1;
    border-color: #a5b5c1;
}

button.plone-navbar-toggle.navbar-toggle {
    width: 53.5px;
    height: 34px;
    padding-top: 2px;
}

nav.plone-navbar {
    background-color: #535758;
    position: relative;
    min-height: 50px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
}

nav.plone-navbar a:hover {
    background-color: #a2a8ab;
}

nav.plone-navbar-toggle {
    float: right;
    width: 55px;
    margin-right: 20px;
    padding-top: 5px;
}

.plone-navbar-nav > .selected > a, .plone-navbar-nav > .selected > a:hover, .plone-navbar-nav > .selected > a:focus {
    color: white;
    background-color: #c5c6c7;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #bdb8b8;
}


/* Login and Searchbox configuration */

#portal-anontools li {
    margin: 5px 0 12px 12px;
}

#portal-header #portal-searchbox .searchSection {
    display: none;
}

#portal-header #portal-searchbox form > div > * {
    font-size: 14px;
    height: 25px;
}

.principal {
    background: #fafafa;
    background-size: 20px 20px;
    /* margin-top: -20px; */
    /* margin-bottom: 20px; */
    padding: 15px 0;
}

div#hero.principal {
    padding-top: 2px;
}

div.LSBox {
    margin-right: 5px;
    height: 30px;
}

div#portal-searchbox {
    float: right;
    margin-left: 0.5em;
    z-index: 2;
}
/*
div#portal-anontools {
    width: 100px;
    float: right;
}
*/
div.searchSection {
    display: none;
}

input.searchButton {
    display: none;
}

input[type="text"], input[type="password"], textarea, select {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #696969;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* Portlet little ball */

.portlet .portletContent > ul > li a::before {
    content: "•";
    position: absolute;
    font-size: 25px;
    margin-top: -9px;
    left: 15px;
    color: rgb(138, 134, 129);
}

/* footer */

#portal-footer-wrapper a {
    color: #ffffff;
}

/* Hide glyphicons */

i.glyphicon.link-external, i.glyphicon.link-https {
    display: none;
}

.plone-breadcrumb {
    margin-top: -3px;
}

h2.tileHeadline {
    font-size: 20px;
}
